import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'

import StickyWrapper from '../shared/StickyWrapper'
import ImgVideo from '../shared/ImgVideo'

// const Wrapper = styled.section`
//   padding-top: 0;
//   pointer-events: none;
//   position: relative;

//   @media only screen and (min-width: 1024px) {
//     padding-top: 100vh;
//   }
// `

const ImgWrapper = styled.div`
  pointer-events: auto;
  position: relative;
  background-color: white;

  @media only screen and (min-width: 1024px) {
    height: 100vh;
    overflow: hidden;
  }
`
const Quote = styled.blockquote`
  position: relative;
  padding: 50px 15px 50px;
  font-size: 38px;
  line-height: 41px;
  pointer-events: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.black};

  @media only screen and (min-width: 768px) {
    font-size: 55px;
    line-height: 60px;
    padding: 80px 30px 80px;
  }

  @media only screen and (min-width: 1024px) {
    padding: 80px 40px 80px;
    font-size: 70px;
    line-height: 76px;
  }
`

// const Question = styled.div`
//   font-family: ${({ theme }) => theme.fontSansMedium};
//   font-size: 16px;
//   line-height: 20px;
//   text-transform: uppercase;
//   margin-bottom: 30px;
//   text-indent: 0;

//   @media only screen and (min-width: 768px) {
//     text-indent: 0;
//   }

//   @media only screen and (min-width: 1024px) {
//     font-size: 20px;
//     margin-bottom: 50px;
//   }
// `

const QuoteInner = styled.div`
  max-width: ${({ theme }) => theme.maxWidthSmall};
  width: 100%;

  @media only screen and (min-width: 1440px) {
    margin-left: auto;
    margin-right: auto;
  }
`

const BigImageText = ({
  fullImage,
  videoLoop,
  quote,
  // question,
  videoLoopSourceDesktop,
  videoLoopSourceMobile,
}) => {
  const [loopSrc, setLoopSrc] = useState(videoLoopSourceMobile)

  const isMob = useMediaQuery({
    query: '(max-width: 767px)',
  })

  useEffect(() => {
    setLoopSrc(isMob ? videoLoopSourceMobile : videoLoopSourceDesktop)
  }, [isMob])

  return (
    <React.Fragment>
      <StickyWrapper noMob scrollTo>
        <ImgWrapper>
          <ImgVideo
            loop={loopSrc}
            image={fullImage}
            fullHeight
          />
        </ImgWrapper>
      </StickyWrapper>
      <StickyWrapper scrollTo>
        <Quote>
          {
            // <QuoteInner>
            // <Question>{question}</Question>
            // </QuoteInner>
          }
          <QuoteInner>{quote}</QuoteInner>
        </Quote>
      </StickyWrapper>
    </React.Fragment>
  )
}

export default BigImageText
