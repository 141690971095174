import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import BasicParagraph from '../styled/Paragraph'
import GraphImg from 'graphcms-image'

import W from '../styled/Wrapper'

const Wrapper = styled(W)`
  padding-top: 100px;
  width: 100%;
  background-color: ${({ theme }) => theme.white};

  @media only screen and (min-width: 1024px) {
    min-height: 100vh;
    padding-top: 50px;
    padding-bottom: 50px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap-column: 40px;
    grid-template-areas:
      '. img'
      '. img'
      '. img'
      'project img'
      'designer img'
      'location img'
      'more img'
      'links img'
      '. img'
      '. img'
      '. img';
    align-items: center;
    align-content: center;
    opacity: ${({ hideTop }) => (hideTop ? 0 : 1)};
    pointer-events: ${({ hideTop }) => (hideTop ? 'none' : 'auto')};
    position: sticky;
    top: 0;
    transform: translateZ(0);
  }
`

const ProjectName = styled(BasicParagraph)`
  font-family: ${({ theme }) => theme.fontSansMedium};
  line-height: 18px;
  margin-bottom: 18px;

  @media only screen and (min-width: 768px) {
    margin-bottom: 22px;
  }
  
  @media only screen and (min-width: 1024px) {
    grid-area: project;
  }
`

const DesignerName = styled(BasicParagraph)`
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 1.94px;

  @media only screen and (min-width: 1024px) {
    font-size: 25px;
    line-height: 30px;
    letter-spacing: 2px;
    grid-area: designer;
  }
`

const Location = styled(BasicParagraph)`
  font-family: ${({ theme }) => theme.fontSansMedium};
  margin-bottom: 27px;

  @media only screen and (min-width: 1024px) {
    grid-area: location;
    margin-bottom: 50px;
  }
`

const Links = styled.div`
  margin-bottom: 100px;

  @media only screen and (min-width: 1024px) {
    margin-bottom: 0;
    grid-area: links;
  }
`

const Instagram = styled(BasicParagraph)`
  font-family: ${({ theme }) => theme.fontSansMedium};
  line-height: 18px;
  display: block;
  font-size: 12px;
  margin-bottom: ${({ marginB }) => (marginB ? '30px' : '10px')};

  @media only screen and (min-width: 1024px) {
    grid-area: instagram;
    margin-bottom: ${({ marginB }) => (marginB ? '10px' : '0')};
  }
`

const Website = styled(BasicParagraph)`
  line-height: 18px;
  font-family: ${({ theme }) => theme.fontSansMedium};
  margin-bottom: 47px;
  display: block;
  font-size: 12px;
  margin-bottom: 10px;

  @media only screen and (min-width: 1024px) {
    grid-area: website;
    margin-bottom: 0;
  }
`

const Img = styled.div`
  width: 100%;
  grid-area: img;

  @media only screen and (min-width: 768px) {
    width: 60%;
  }

  @media only screen and (min-width: 1024px) {
    width: 100%;
  }
`

const MoreAbout = styled(BasicParagraph)`
  margin-bottom: 50px;
  padding-top: 50px;

  @media only screen and (min-width: 1024px) {
    display: flex;
    align-items: center;
    min-height: 174px;
    padding-left: 117px;
    padding-right: 47px;
    grid-area: more;
    padding-top: 0;
    max-width: 923px;
  }
`

const Info = ({ data }) => {
  const [hideTop, setHideTop] = useState(false)

  const {
    about,
    instagramHandle,
    instagramHandle2,
    // aboutHeadlines,
    location,
    name,
    portrait,
    projectName,
    website,
    websiteUrl,
    website2,
    websiteUrl2,
  } = data

  const handleScroll = () => {
    setHideTop(window.scrollY > window.innerHeight)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <Wrapper hideTop={hideTop}>
      <ProjectName size="xs" uppercase>{projectName}</ProjectName>
      <DesignerName uppercase>{name}</DesignerName>
      <Location size="xs" uppercase>
        {location}
      </Location>
      <Img>
        <GraphImg
          image={portrait}
          backgroundColor={'pink'}
          height={500}
          maxWidth={422}
          blurryPlaceholder={false}
        />
      </Img>
      <MoreAbout size="m">{about}</MoreAbout>
      {(website || instagramHandle) && (
        <Links>
          {instagramHandle && (
            <Instagram
              size="xs"
              uppercase
              as="a"
              href={`https://instagram.com/${instagramHandle}`}
              target="_blank"
            >
              @{instagramHandle}
            </Instagram>
          )}
          {instagramHandle2 && (
            <Instagram
              size="xs"
              uppercase
              as="a"
              href={`https://instagram.com/${instagramHandle2}`}
              target="_blank"
              marginB
            >
              @{instagramHandle2}
            </Instagram>
          )}
          {website && (
            <Website
              as="a"
              href={websiteUrl}
              target="_blank"
              size="xs"
              uppercase
            >
              {website}
            </Website>
          )}
          {website2 && (
            <Website
              as="a"
              href={websiteUrl2}
              target="_blank"
              size="xs"
              uppercase
            >
              {website2}
            </Website>
          )}
        </Links>
      )}
    </Wrapper>
  )
}

export default Info
