import React from 'react'
import styled from 'styled-components'
import GraphImg from 'graphcms-image'

import H from '../styled/Heading'
import P from '../styled/Paragraph'

const Wrapper = styled.li`
  position: relative;
  z-index: 2;
  padding-bottom: 90px;
  color: ${({ theme }) => theme.white};
  list-style: none;

  @media only screen and (min-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 40px;
    grid-template-areas: ${({ inverse }) =>
      inverse ? `'text image'` : `'image text'`};
    /* align-items: center; */
    align-items: start;
    padding-bottom: 150px;
  }
`

const TextWrapper = styled.div`
  padding-bottom: 24px;

  @media only screen and (min-width: 1024px) {
    grid-area: text;
    max-width: 540px;
    padding-bottom: 54px;
  }
`

const Title = styled(H)`
  margin-bottom: 15px;

  @media only screen and (min-width: 1024px) {
    margin-bottom: 30px;
  }
`

const Text = styled(P)`
  margin-bottom: 30px;
`

const ImageWrapper = styled.div`
  grid-area: image;
  position: relative;

  @media only screen and (min-width: 768px) {
    width: 75%;
  }

  @media only screen and (min-width: 1024px) {
    width: 100%;
  }
`

const Image = styled.div`
  margin-bottom: 10px;

  .graphcms-image-outer-wrapper {
    width: ${({ fixedRatio }) => (fixedRatio ? '100%' : undefined)};
    height: ${({ fixedRatio }) =>
      fixedRatio ? 'calc((100vw - 30px) / 1.77)' : undefined};
    overflow: ${({ fixedRatio }) => (fixedRatio ? 'hidden' : undefined)};

    @media only screen and (min-width: 768px) {
      height: ${({ fixedRatio }) =>
        fixedRatio ? 'calc((100vw - 60px) * 0.75 / 1.77)' : undefined};
    }

    @media only screen and (min-width: 1024px) {
      height: ${({ fixedRatio }) =>
        fixedRatio ? 'calc((50vw - 60px) / 1.77)' : undefined};
    }

    & > div {
      height: ${({ fixedRatio }) =>
        fixedRatio ? '100% !important' : undefined};
    }
  }
`

const Caption = styled(P)`
  opacity: 0.6;
  line-height: 1.3;

  @media only screen and (min-width: 1024px) {
    width: 100%;
    text-align: center;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.15s ease-in-out;
    position: absolute;

    ${ImageWrapper}:hover & {
      opacity: 0.6;
      pointer-events: auto;
    }
  }
`

const ImageListItem = ({ data, inverse }) => {
  return (
    <Wrapper inverse={inverse}>
      <TextWrapper>
        {data.heading && <Title size={4}>{data.heading}</Title>}
        {data.text && <Text size={'s'}>{data.text}</Text>}
      </TextWrapper>
      {data.image && (
        <ImageWrapper>
          <Image fixedRatio>
            <GraphImg
              image={data.image}
              backgroundColor={'transparent'}
              maxWidth={400}
              blurryPlaceholder={false}
            />
          </Image>
          <Caption size="xs" uppercase sans bold>
            {data.imageCaption}
          </Caption>
        </ImageWrapper>
      )}
    </Wrapper>
  )
}

export default ImageListItem
