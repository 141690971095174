import React from 'react'
import styled from 'styled-components'

import Item from './ImageListItem'
import W from '../styled/Wrapper'


const Wrapper = styled(W)`
  padding-top: 120px;
  padding-bottom: 30px;
  background-color: ${({ theme }) => theme.black};
  color: ${({ theme }) => theme.white};
  position: relative;
  z-index: 1;

  @media only screen and (min-width: 1024px) {
    padding-top: 160px;
  }
`
const List = styled.ul`
  list-style: none;
  margin: 0;
`

const ImageList = ({data}) => {
  return (
    <Wrapper>
       <List>
        {data.map((item, i) => {
          return <Item key={i} data={item} inverse={(i + 1) % 2 === 0} />
        })}
      </List>
    </Wrapper>
  )
}

export default ImageList
