import React from 'react'
import styled, { keyframes } from 'styled-components'
import BasicParagraph from '../styled/Paragraph'
import { StaticQuery, graphql, Link } from 'gatsby'
import GraphImg from 'graphcms-image'

const move = keyframes`
  0% {
    transform: translateY(0px);
    opacity: 1;
  }

	17% {
		opacity:0.5;
	}
	34%{
    transform: translateY(-10px);
	  opacity: 0;
		
	}
	50% {
    transform: translateY(10px);
	  opacity: 0;
  }
  67% {
    opacity: 0.5;
  }
  100% {
    transform: translateY(0px);
	 opacity: 1;
  }
`

const Wrapper = styled.section`
  position: relative;
  z-index: 2;
`

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.white};
  position: relative;

  @media only screen and (min-width: 1024px) {
    flex-direction: row;
  }
`
const Button = styled(Link)`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.black};
  display: flex;
  justify-content: space-between;
  text-align: ${({ prev }) => (prev ? 'left' : 'right')};
  padding: 15px;
  border-top: 1px solid ${({ theme }) => theme.black};

  &:last-of-type {
    border-top: 0;

    @media only screen and (min-width: 1024px) {
      border-top: 1px solid ${({ theme }) => theme.black};
    }
  }

  @media only screen and (min-width: 1024px) {
    border-right: ${({ prev, theme }) =>
      prev ? `1px solid ${theme.black}` : '0'};
    border-top: 1px solid ${({ theme }) => theme.black};
    padding: ${({ prev }) => (prev ? '8px 8px 8px 40px' : '8px 40px 8px 8px')};
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (min-width: 1024px) {
    flex-direction: row;
    align-items: center;
    padding-top: 3px;
  }
`

const Text = styled(BasicParagraph)`
  font-family: ${({ theme }) => theme.fontSansMedium};
  margin-top: -8px;

  @media only screen and (min-width: 1024px) {
    margin-top: 0;
    order: ${({ prev }) => (prev ? '-1' : '1')};
    margin-right: ${({ prev }) => (prev ? '40px' : '0')};
    margin-left: ${({ prev }) => (prev ? '0' : '40px')};
    line-height: 1;
  }
`

const Name = styled(BasicParagraph)`
  font-size: 18px;
  letter-spacing: 1.46px;
  line-height: 24px;

  @media only screen and (min-width: 1024px) {
    font-size: 23px;
    letter-spacing: 1.86px;
    line-height: 1;
    max-width: 490px;
  }

  @media (hover: hover) {
    ${Button}:hover & {
      animation: ${move} 0.45s ease-out;
    }
  }
`

const ImageWrapper = styled.div`
  justify-self: flex-start;
  height: 61px;
  width: 61px;
  object-fit: cover;
  overflow: hidden;
  order: ${({ prev }) => (prev ? 1 : -1)};

  div {
    /* placeholder fix */
    max-height: 61px;
    padding-bottom: 61px !important;
  }
`

const Suggested = ({ currentId }) => {
  return (
    <Wrapper>
      <StaticQuery
        query={graphql`
          query PrevProject {
            dis {
              designers {
                name
                id
                slug
                portrait {
                  height
                  handle
                  width
                }
              }
            }
          }
        `}
        render={data => {
          const designers = data.dis.designers

          const currIndex = designers.findIndex(el => el.id === currentId)

          const prev =
            currIndex === 0
              ? designers[designers.length - 1]
              : designers[currIndex - 1]
          const next =
            currIndex === designers.length - 1
              ? designers[0]
              : designers[currIndex + 1]

          return (
            <Buttons>
              <Button to={`/designers/${prev.slug}`} prev>
                <TextWrapper>
                  <Text size="xs" uppercase prev>
                    Previous
                  </Text>
                  <Name uppercase>{prev.name}</Name>
                </TextWrapper>
                <ImageWrapper prev>
                  <GraphImg
                    image={prev.portrait}
                    backgroundColor={'transparent'}
                    width={150}
                    height={150}
                    blurryPlaceholder={false}
                  />
                </ImageWrapper>
              </Button>

              <Button to={`/designers/${next.slug}`}>
                <TextWrapper>
                  <Text size="xs" uppercase>
                    Next
                  </Text>
                  <Name uppercase>{next.name}</Name>
                </TextWrapper>
                <ImageWrapper>
                  <GraphImg
                    image={next.portrait}
                    backgroundColor={'transparent'}
                    maxWidth={400}
                    blurryPlaceholder={false}
                  />
                </ImageWrapper>
              </Button>
            </Buttons>
          )
        }}
      />
    </Wrapper>
  )
}

export default Suggested
