import React from 'react'
import SEO from '../components/seo'

import Info from '../components/project/Info'
import BigImageSmallText from '../components/project/BigImageSmallText'
import ImageList from '../components/project/ImageList'
// import Carousel from '../components/project/Carousel'
import Suggested from '../components/project/Suggested'
import StickyWrapper from '../components/shared/StickyWrapper'

const Project = props => {
  const data = props.pageContext.data

  const {
    seoTitle,
    seoDescription,
    seoImage,
    id,
    fullImage,
    question,
    quote,
    videoLoop,
    videoLoopSourceDesktop,
    videoLoopSourceMobile,
    projectDocumentations,
  } = data

  return (
    <div>
      <SEO
        title={seoTitle}
        description={seoDescription}
        image={seoImage && seoImage.url}
      />

      <StickyWrapper noMob scrollTo>
        <Info data={data} />
      </StickyWrapper>
        <BigImageSmallText
          fullImage={fullImage}
          videoLoop={videoLoop}
          quote={quote}
          question={question}
          videoLoopSourceDesktop={videoLoopSourceDesktop}
          videoLoopSourceMobile={videoLoopSourceMobile}
        />

      <StickyWrapper noMob scrollTo>
        <div id="d-01"></div>
        <ImageList data={projectDocumentations} />
        <div id="l-01"></div>
        {
          // <Carousel data={projectDocumentations} />
        }

        <Suggested currentId={id} />
      </StickyWrapper>

      <div id="d-02"></div>
      <div id="l-02"></div>
      <div id="d-03"></div>
      <div id="l-03"></div>
      <div id="d-04"></div>
      <div id="l-04"></div>
    </div>
  )
}

export default Project
